import React, { Fragment } from "react"; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne"; 
import Services from '../components/Services1/home-four' 


const servicePage = () => {
	return (
	  <Fragment>
		<MetaTags>
		  <title> SAPIT </title>
		  <meta
			name="description"
			content=" SAPIT "
		  />
		</MetaTags>
		<LayoutOne>
		<div className="service-page">
		
			<Services /> 
		</div>
		</LayoutOne>
	  </Fragment>
	);
  };
  
  export default servicePage;
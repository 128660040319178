import React from "react";  
import innovationData from '../../data/Services/innovation-one';

const ServiceContentWrap = ({service}) => {
    return (
        <div className="col-md-6 col-sm-12"> 
          <p className="about_para">{innovationData.text}</p>
        
    </div>  
        
    );
};

export default ServiceContentWrap;
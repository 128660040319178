import React, { Fragment } from "react";
import PageHeader from '../../components/PageHeader'
import ServicesData from '../../data/Services/innovation-one'
import ServiceContent from "./innovationContent";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
const ServiceDetails = () => {
	const serviceID = new URLSearchParams(window.location.search).get("id");
	const service = ServicesData.find((serviceItem) => serviceItem.id === parseInt(serviceID));
	return (
		<Fragment>
			<PageHeader
				bgImg={require('../../assets/images/page_bg.jpg')}
				title={service.title}
			/>
			<section className="about-section" id="about">
				<div className="container">
					<SectionTitle
						title={service.title}
					/>
					<div className="row">
						<div className="col-md-6 col-sm-12 about_bottom_warp">
							<div className="about_list">
							<div  style={{ fontSize: 14, textAlign: 'justify' }}  dangerouslySetInnerHTML={{ __html: service.description }}>  
								</div>
						
							{/* <div className="row about_list_warp"> */}
								{ServicesData.map(about => (
									<ServiceContent
										key={service.id}
										title={service.title}
										text={service.text}
									/>
								))}
							{/* </div> */}
								</div>
						</div>
						<div className="col-md-6 col-sm-12">
							<img src={require('../../assets/images/' + service.thumb)} alt="service" />

						</div>
					</div>
				</div>
			</section>
		</Fragment>
	);
};

export default ServiceDetails;




import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Slider from '../components/Slider/SliderOne'
import SliderPromo from '../components/SliderPromo'
import Service from '../components/Services/home-two'
import BlogPageContent from "../templates/Blog/index";
import LogoContainer from '../components/BrandLogo';
import ContactForm from '../components/ContactForm';



const Home = () => {
  return (
    <Fragment>
      <MetaTags>
        <title> SAPIT </title>
        <meta
          name="description"
          content="SAPIT"
        />
      </MetaTags>
      <LayoutOne>
        <Slider />
        <SliderPromo/>
         <Service />
         <BlogPageContent />
       
        <LogoContainer />
        <ContactForm />

      </LayoutOne>
    </Fragment>
  );
};

export default Home;

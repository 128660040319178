import React from 'react'; 
import {Link} from "react-router-dom"; 
import ListGroup from "react-bootstrap/ListGroup"

function ServiceItem(props) {
    const serviceURL = `/offre/${props.title.split(' ').join('-').toLowerCase()}?id=${props.id}`
    return ( 
   <div className="col-sm-12"> 
    <div className="service_list"> 
    <ListGroup>
        <ListGroup.Item >
          <Link  to={`${process.env.PUBLIC_URL + serviceURL}`}>
            <h5> {props.title} </h5> 
          </Link> 
        </ListGroup.Item>
    </ListGroup>     
    </div>         
    </div>
    );
    }

export default ServiceItem;

 
import React, { useState, useEffect } from "react";
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";  
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
 
const Footer = () => {
 
    const [scroll, setScroll] = useState(0);
    const [top, setTop] = useState(0);
  
    useEffect(() => {
      setTop(100);
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      animateScroll.scrollToTop();
    };
  
    const handleScroll = () => {
      setScroll(window.scrollY);
    };

    return(
    <footer className="footer-section">
        <div className="container">
            <div className="row"> 
            <div className="col-lg-4 col-sm-12">
					<Widget title="Contact">
                    <p>ADRESSE : </p>
                    <p>91, RUE DU FAUBOURG SAINT-HONORE, </p> <p> 75008 PARIS  </p>
                    <p>NUMERO : </p>
                    <p> +33 (0)6 64 61 44 06</p>
                    </Widget>
                </div> 
                <div className="col-lg-4 col-sm-12"> 
                    <Widget title="Nos solutions">
                        <List classes="recent-post helpful_post">
                            <LI>
                                <Link to={process.env.PUBLIC_URL + "/Solutions/matching-intelligent?id=1"}>Matching Intelligent</Link>
                            </LI>
                            <LI>
                                <Link to={process.env.PUBLIC_URL + "/Solutions/sourcing-des-cvs-?id=2"}>Sourcing Des CVs</Link>
                            </LI>
                            <LI>
                                <Link to={process.env.PUBLIC_URL + "/Solutions/AnalyseCV-Offres?id=3"}>Analyse CV-Offres</Link>
                            </LI> 
                            <LI>
                                <Link to={process.env.PUBLIC_URL + "/Solutions/MatchingIntelligentCV?id=4"}>Matching Intelligent CV</Link>
                            </LI> 
                            <LI>
                                <Link to={process.env.PUBLIC_URL + "/Solutions/ChatbotConversationnel?id=5"}>Chatbot Conversationnel</Link>
                            </LI> 
                        </List>
                    </Widget> 
                </div> 
                <div className="col-lg-4 col-sm-12"> 
                    <Widget title="Recherche et Innovation">
                        <List classes="recent-post helpful_post">
                            <LI>
                                <Link to={process.env.PUBLIC_URL + "/Recherche&Innovation/innovation?id=1"}> Recherche & Développement </Link>
                            </LI>
                            <LI>
                                <Link to={process.env.PUBLIC_URL + "/Recherche&Innovation/intelligence-artificielle?id=2"}>Intelligence Artificielle</Link>
                            </LI>
                            <LI>
                                <Link to={process.env.PUBLIC_URL + "/Recherche&Innovation/rh?id=3"}>Ressources Humaines</Link>
                            </LI> 
                        </List>
                    </Widget> 
                </div> 
              
           
                
                <div className="subfooter"> 
                    <div className="copyright_text"> &copy; {new Date().getFullYear()} SAPIT <Link to={process.env.PUBLIC_URL + "/"}> </Link>
                    </div> 

                    {/* <div className="footer_social"> 
                        <ul className="footer_social_icons">
                        <li> <a href="//facebook.com"><i className="fa fa-facebook"></i></a> </li>
                        <li> <a href="//twitter.com"><i className="fa fa-twitter"></i></a> </li>
                        </ul>
                    </div> */}

                    <button className={`scrollup ${scroll > top ? "show" : ""}`} onClick={() => scrollToTop()} > 
                        <span className="arrow_triangle-up"></span>
                    </button> 
                </div> 

            </div>
        </div> 

    </footer> 

        )

}

 
export default Footer;
import React, { Fragment } from 'react';
import SectionTitle from "../../SectionTitles/SectionTitle";
import PricingList from  './PricingList'
import pricingData from '../../../data/PricingTable/pricingTable'
import PageHeader from '../../../components/PageHeader'


const Pricing  = ({classes}) => {
    return (
        <Fragment>
            <PageHeader
                    bgImg={require('../../../assets/images/page_bg.jpg')}
                    parentMenu=" Financement De L'innovation   " 
                    parentMenuLink = "financement"
                />
       
		<section className={`pricing-section ${classes}`} id="pricing"> 
        
        	<div className="container">
				{/* Heading */} 
				<SectionTitle 
					title="Nos Services" 
				/>
				{/* End: Heading */ }
				<div className="row"> 
					{
                        pricingData.map(pricing=>(
                            <PricingList
                                key={pricing.id} 
                                currencyType={pricing.currencyType}
                                subscribePlan={pricing.subscribePlan}
                                planFeatures={pricing.planFeatures}
                                buttonLink={pricing.buttonLink}
                            />
                        ))
                    }
				</div>
 
			</div>
		</section>
        </Fragment>
    );
}

export default Pricing;
import React from "react";
import {  BrowserRouter as Router, Switch, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import ScrollToTop from "./helpers/ScrollToTop";
import Home from "./pages/Home";
import NosServices from "./pages/NosServices";
import NotreMission from './pages/NotreMission';
import Expertise from "./pages/Expertise";
import Service1 from "./pages/Service1";
import Innovation from "./pages/Innovation";
import Financement from "./pages/Financement";
import SingleService from "./pages/SingleService";
import SingleService1 from "./pages/SingleService1";
import SingleInnovation from "./pages/SingleInnovation";
import SingleGallery from "./pages/SingleGallery";
import SingleOffre from "./pages/SingleOffre";
import Contact from "./pages/Contact";
import Candidature from "./pages/Candidature";
import SingleBlog from "./pages/SingleBlog";
import Solution from "./pages/Solution";

function App() {
  return (
    <Router >
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={Home}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/accueil"}`}
            component={Home}
          />
          <Route exact path={`${process.env.PUBLIC_URL + '/expertise'}`}
            component={Expertise}
          />
           <Route exact path={`${process.env.PUBLIC_URL + '/offres'}`}
            component={Service1}
          />
          <Route exact path={`${process.env.PUBLIC_URL + '/Solutions'}`}
            component={Innovation}
          />
           <Route exact path={`${process.env.PUBLIC_URL + '/financement'}`}
            component={Financement}
          />
          <Route exact path={`${process.env.PUBLIC_URL + '/NosServices'}`}
            component={NosServices}
          />
          <Route exact path={`${process.env.PUBLIC_URL + '/NotreMission'}`}
            component={NotreMission}
          />
          <Route path={`${process.env.PUBLIC_URL + '/expertise/:serviceIDD'}`}
            component={SingleService}
          />
           <Route path={`${process.env.PUBLIC_URL + '/offre/:service1IDD'}`}
            component={SingleService1}
          />
          <Route path={`${process.env.PUBLIC_URL + '/Solutions/:innovationIDD'}`}
            component={SingleInnovation}
          />
          <Route path={`${process.env.PUBLIC_URL + '/gallery/:galleryID'}`}
            component={SingleGallery}
          />
          <Route path={`${process.env.PUBLIC_URL + '/Recherche&Innovation/:blogID'}`}
            component={SingleBlog}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
           <Route
            path={`${process.env.PUBLIC_URL + "/candidature"}`}
            component={Candidature}
          />
          <Route path={`${process.env.PUBLIC_URL + '/offre/:offreIDD'}`}
            component={SingleOffre}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/Recherche&Innovation"}`}
            component={Solution}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;

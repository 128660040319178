import React from 'react';
 
const COntactInfo = () => {

    return (

      <div className="row">
        <div className="bottom_contact col-sm-6 col-xs-12"><i className="icon_pin_alt"></i>
            <p>ADRESSE : </p>
            <h4> 91, RUE DU FAUBOURG SAINT-HONORE, </h4>
            <h4> 75008 PARIS  </h4>
        </div>
   

        <div className="bottom_contact col-sm-6 col-xs-12"><i className="icon_phone"></i>
            <p>NUMERO : </p>
            <h4> +33 (0)6 64 61 44 06</h4>
        </div>
      </div>

    );
}

export default COntactInfo;
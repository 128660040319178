import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import PricingTable from '../components/PricingTable/home-one';

const Home = () => {
  return (
    <Fragment>
      <MetaTags>
        <title> SAPIT  | Nos services</title>
        <meta
          name="description"
          content=" SAPIT "
        />
      </MetaTags>
      <LayoutOne>

        <PricingTable />
        

      </LayoutOne>
    </Fragment>
  );
};

export default Home;
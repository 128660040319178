import React from "react";
import { Link } from "react-router-dom"; 

const PageHeader = ({ title,bgImg, parentMenu, parentMenuLink }) => {
  return ( 

    <header id="page-top" className="blog-banner" style={{backgroundImage: `url(${bgImg})`,height:'80px'}}>
        <div className="container" id="blog">
            <div className="row blog-header text-left">
                <div className="col-sm-12"> 
                    <h4><Link to={process.env.PUBLIC_URL + "/"}> Accueil </Link> &gt; {title} <Link to={process.env.PUBLIC_URL + "/"+ parentMenuLink}> {parentMenu}  </Link> </h4>
                  
                    <br />
                    <br />
                    {/* <h2>{title}</h2> */}
                </div>
            </div>
        </div>  
    </header> 
    
  );
};
 
export default PageHeader;

 
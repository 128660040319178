import React, { Fragment } from "react";


const ServiceContentWrap = ({ service }) => {
    return (
        <Fragment>

            <div className="col-md-9 col-sm-12 single_service_page_content">
                <img src={require('../../assets/images/' + service.singleThumb)} alt="service" />
                <div className="single_service_title">
                    <h3>{service.title}</h3>
                    {<div dangerouslySetInnerHTML={{ __html: service.description }} />}
                    
                </div>
                

            </div>

        </Fragment>
    );
};

export default ServiceContentWrap;
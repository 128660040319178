import React, { Fragment } from "react";
import PageHeader from '../../components/PageHeader';
import ServicesData from '../../data/Services/offre-one';
import PageWrapper from "../../components/PageWrapper";
import ServiceContent from "./Offre1Content";

const OffreSingle = () => {
	const serviceID = new URLSearchParams(window.location.search).get("id");
	const service = ServicesData.find((serviceItem) => serviceItem.id === parseInt(serviceID));

	return (
		<Fragment>

			<PageHeader
				bgImg={require('../../assets/images/page_bg.jpg')}
				title={service.title}
				
			/>

			<PageWrapper classes="single_service_section">

				<ServiceContent
					service={service}
				/>
			</PageWrapper>

		</Fragment>
	);
};

export default OffreSingle;




import React, { Fragment } from 'react';
// import ServicesData from '../../../data/Services/offre-one'
// import ServiceItem from "./ServiceItem";
import SectionTitle from "../../SectionTitles/SectionTitle";
// import bgImg from '../../../assets/images/page_bg.jpg'
import { Link } from 'react-router-dom';
import ListGroup from "react-bootstrap/ListGroup"
import PageHeader from '../../../components/PageHeader'

const Service = () => {
    return (
        <Fragment>
            <PageHeader
                    bgImg={require('../../../assets/images/page_bg.jpg')}
                    title="Financement de l'innovation" 
                />
            <section className="about-section" id="about">
                <div className="container">
                    <SectionTitle
                        title={"Financement de l'innovation"}
                    />
                    {/* <div className="col-md-12 col-sm-12 blog-banner" style={{backgroundImage: `url(${slider4})`,height:'200px'}}  >
                        </div> */}
                    <div className="row">
                        <div className="col-md-6 col-sm-12 about_bottom_warp">

                            <div className="about_list">

                                <p>Le financement est un levier pour renforcer et accélérer la capacité à innover des entreprises. Au-delà des fonds alloués aux entreprises pour mener à bien leurs projets R&D, le financement de l’innovation permet d’accélérer la croissance des entreprises en soutenant les idées et les projets qui seront créateurs de valeur ajoutée.
                                 <br />Nous accompagnons les entreprises innovantes pour bénéficier de nombreux dispositifs financiers de soutien pour leurs activités de recherche, développement et innovation. Pour valoriser ces projets, nous travaillons avec elles sur la mise en place de plusieurs outils de financement :
                                 <br /><br /> •	Le financement a priori permet d’obtenir une aide ciblée pour financer un projet futur sous la forme d’aide à l’investissement, à l’amorçage, de prêts, de bourses, de subventions locales, régionales, nationales ou encore européennes.
                                 <br /><br /> •	Le financement a posteriori correspond à l’obtention d’un financement à l’issue des investissements R&D&I.</p>
                            </div>
                            <div className="text-center">

                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div >
                                <div className="service_list">
                                    <ListGroup>
                                        <ListGroup.Item >
                                            <Link to={`${process.env.PUBLIC_URL + '/NosServices'}`}>
                                                <h5> Nos Services </h5>
                                            </Link>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                                <div className="service_list">
                                    <ListGroup>
                                        <ListGroup.Item >
                                            <Link to={`${process.env.PUBLIC_URL + '/NotreMission'}`}>
                                                <h5> Notre Mission</h5>
                                            </Link>

                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>

        // <section className="service_section" id="agenda">
        //     <div className="container">

        //         <SectionTitle
        //             title="Financement de l'innovation"
        //         />
        //         <p>Le financement est un levier pour renforcer et accélérer la capacité à innover des entreprises. Au-delà des fonds alloués aux entreprises pour mener à bien leurs projets R&D, le financement de l’innovation permet d’accélérer la croissance des entreprises en soutenant les idées et les projets qui seront créateurs de valeur ajoutée. 
        //            <br />Nous accompagnons les entreprises innovantes pour bénéficier de nombreux dispositifs financiers de soutien pour leurs activités de recherche, développement et innovation. Pour valoriser ces projets, nous travaillons avec elles sur la mise en place de plusieurs outils de financement : 
        //           <br /><br /> •	Le financement a priori permet d’obtenir une aide ciblée pour financer un projet futur sous la forme d’aide à l’investissement, à l’amorçage, de prêts, de bourses, de subventions locales, régionales, nationales ou encore européennes.
        //           <br /><br /> •	Le financement a posteriori correspond à l’obtention d’un financement à l’issue des investissements R&D&I.</p>

        //     </div>
        // </section>
    );
}
    ;

export default Service;
import React from 'react'; 
import Form from './Form'
import SectionTitle from "../SectionTitles/SectionTitle";




  
const CandidatureForm = () => {

    return (
        <div className="contact-section" id="contact">
            <div className="container"> 
              {/* Heading */}
              <SectionTitle
                  title="Candidature spontanée" 
                />

                <div className="row inner-contact"> 
                    

                      <div className="col-sm-12">
                          <Form />
                      </div>
                       
                </div> 
            </div> 
        </div>

    );
}

export default CandidatureForm;
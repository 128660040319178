import React, { Fragment } from 'react';
// import ServicesData from '../../../data/Services/offre-one'
// import ServiceItem from "./ServiceItem";
import SectionTitle from "../../SectionTitles/SectionTitle";
import service_1 from '../../../assets/images/service_1.jpg'
import PageHeader from '../../../components/PageHeader'


const Service = () => {
    return (

        <Fragment>
            <PageHeader
                    bgImg={require('../../../assets/images/page_bg.jpg')}
                    parentMenu=" Financement De L'innovation   " 
                    parentMenuLink = "financement"
                    
                />

            <section className="about-section" id="about">
                <div className="container">
                    <SectionTitle
                        title={'Notre Misssion'}
                    />
                    <div className="row">
                        <div className="col-md-6 col-sm-12 about_bottom_warp">
                            <div className="about_list">

                                <p>Pour vous accompagner, notre mission se base sur trois axes :
                                 <br /> <br />1 GUIDER : Un encadrement complet et personnalisé dans la faisabilité et la réalisation de vos projets, parfaitement en phase avec vos enjeux et vos problématiques.
                                 <br /> <br />2 SÉCURISER : Une action respectueuse de votre entreprise et des attentes de l’administration fiscale, grâce à la maîtrise des dispositifs fiscaux et des règles éthiques.
                                 <br /> <br />3 SOUTENIR : Un accompagnement à chaque étape d’évolution de votre demande et la défense de votre dossier auprès de l’administration fiscale.</p>
                            </div>

                        </div>
                        <div className="col-md-6 col-sm-12">

                            <img src={service_1} alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
        // <section className="service_section" id="agenda">
        //     <div className="container">

        //         <SectionTitle
        //             title="Notre Misssion"
        //         />
        //         <p>Pour vous accompagner, notre mission se base sur trois axes :
        //         <br /> <br />1 GUIDER : Un encadrement complet et personnalisé dans la faisabilité et la réalisation de vos projets, parfaitement en phase avec vos enjeux et vos problématiques.
        //         <br /> <br />2 SÉCURISER : Une action respectueuse de votre entreprise et des attentes de l’administration fiscale, grâce à la maîtrise des dispositifs fiscaux et des règles éthiques.
        //         <br /> <br />3 SOUTENIR : Un accompagnement à chaque étape d’évolution de votre demande et la défense de votre dossier auprès de l’administration fiscale.</p>

        //     </div>
        // </section>
    );
}
    ;

export default Service;